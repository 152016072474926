import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 20,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '80%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

function WhensdayTable(props) {
  const { classes } = props;
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <CustomTableCell>Title</CustomTableCell>
            <CustomTableCell>Date</CustomTableCell>
            <CustomTableCell>Location</CustomTableCell>
            <CustomTableCell>Notes</CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((event, index) => {
            return (
              <TableRow key={index}>
                <CustomTableCell component="th" scope="row">
                  {event.title}
                </CustomTableCell>
                <CustomTableCell>{event.dateDescription}</CustomTableCell>
                <CustomTableCell>{event.location}</CustomTableCell>
                <CustomTableCell>{event.notes}</CustomTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
}

WhensdayTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WhensdayTable);

