import React from 'react';
import './App.css';
//TODO: proper credit for license
import {Editor} from 'draft-js';

class WhensdayEditor extends React.Component {
    constructor(props) {
      super(props);
  
      this.focus = () => this.refs.editor.focus();
    }
    render() {
      return (
        <div className="input-area" onClick={this.focus}>
          <Editor
            editorState={this.props.editorState}
            onChange={this.props.onChange}
            placeholder="Enter some text..."
            ref="editor"
          />
        </div>
      );
    }
}
  
export default WhensdayEditor;