import React, { Component } from 'react';
import './App.css';
import axios from "axios";
//TODO: proper credit for license

import {EditorState, SelectionState, Modifier, CompositeDecorator, convertFromRaw, convertToRaw, CharacterMetadata} from 'draft-js';

import WhensdayTable from './WhensdayTable';
import WhensdayEditor from './WhensdayEditor';
import TagButtons from './TagButtons';
import SendButton from './SendButton';

const tagnames = {
  title: 'TITLE',
  date: 'TIME',
  location: 'LOCATION',
  notes: 'NOTES'
}

const axiosConfig = {
  headers: {
      'Content-Type': 'application/json;charset=UTF-8',
  }
};

const entityMap = {
  'TITLE_MANUAL': {
    type: 'TOKEN',
    mutability: 'MUTABLE',
    data: {'tag': tagnames.title, 'isAutomatic': false}
  },
  'TIME': {
    type: 'TOKEN',
    mutability: 'MUTABLE',
    data: {'tag': tagnames.date, 'isAutomatic': true}
  },
  'LOCATION': {
    type: 'TOKEN',
    mutability: 'MUTABLE',
    data: {'tag': tagnames.location, 'isAutomatic': true}
  },
  'NOTES_MANUAL': {
    type: 'TOKEN',
    mutability: 'MUTABLE',
    data: {'tag': tagnames.notes, 'isAutomatic': false}
  },
  'TIME_MANUAL': {
    type: 'TOKEN',
    mutability: 'MUTABLE',
    data: {'tag': tagnames.date, 'isAutomatic': false}
  },
  'LOCATION_MANUAL': {
    type: 'TOKEN',
    mutability: 'MUTABLE',
    data: {'tag': tagnames.location, 'isAutomatic': false}
  },
};

const rawContent = {
  blocks: [
    {
      text: (
        ''
      ),
      type: 'unstyled',
    },
  ],
  entityMap: entityMap
};

const TokenSpan = (props) => {
  const style = getDecoratedStyle(
    props.contentState.getEntity(props.entityKey).get('data').tag
  );
  return (
    <span data-offset-key={props.offsetkey} className={style}>
      {props.children}
    </span>
  );
};

function getEntityStrategy(tag) {
  return function(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        if (entityKey === null) {
          return false;
        }
        return contentState.getEntity(entityKey).get('data').tag === tag;
      },
      callback
    );
  };
}

function getDecoratedStyle(tagName) {
  switch (tagName) {
    case tagnames.title: return 'tag-blue';
    case tagnames.date: return 'tag-red';
    case tagnames.location: return 'tag-green';
    case tagnames.notes: return 'tag-yellow';
    default: return null;
  }
}

class App extends Component {

  // default State object
  constructor(props){
    super(props);

    const decorator = new CompositeDecorator([
      {
        strategy: getEntityStrategy(tagnames.title),
        component: TokenSpan,
      },
      {
        strategy: getEntityStrategy(tagnames.date),
        component: TokenSpan,
      },
      {
        strategy: getEntityStrategy(tagnames.location),
        component: TokenSpan,
      },
      {
        strategy: getEntityStrategy(tagnames.notes),
        component: TokenSpan,
      }
    ]);
    const blocks = convertFromRaw(rawContent);

    this.state = {
      language: "de",
      format: "DD.MM.YY",
      separator: ".",
      defaultduration: 60,
      responseType: "EDIT",
      text: "",
      events: [],
      editorState: EditorState.createWithContent(blocks, decorator)
    };

    this.handleTagClick = (event) => {
      const editorState = this.state.editorState;
      const content = editorState.getCurrentContent();
      const selection = editorState.getSelection();

      const newContent = Modifier.applyEntity(
        content,
        selection,
        this.findEntityKey(event.target.innerText, false)
      );

      const newEditorState = EditorState.push(editorState, newContent, "apply-entity");
      this.setState({'editorState': newEditorState});
    }

    this.logState = () => {
      const content = this.state.editorState.getCurrentContent();
      console.log(convertToRaw(content));
    };

    this.handleSend = (event) => {
      const editorState = this.state.editorState;
      const content = editorState.getCurrentContent();
      const raw = convertToRaw(content);
      const blocks = raw.blocks;
      const entityMap = raw.entityMap;
      var paragraphs = []
      
      blocks.forEach( block => {
        var tags = []
        block.entityRanges.forEach( entity => {
            tags.push({
              "start": entity.offset,
              "end": (entity.offset + entity.length),
              "isAutomatic": entityMap[entity.key].data.isAutomatic,
              "tag": entityMap[entity.key].data.tag
            });
          }
        );
        paragraphs.push({
          'key': block.key,
          'text': block.text,
          'tags': tags
        });
      });
      this.sendParseRequest(paragraphs);
    }

    this.handleChange = (editorState) => {
      this.setState({'editorState': editorState});
    }
  }
  sendParseRequest(paragraphs) {

    var requestData = {
      "language":"de",
      "format":"DD.MM.YY",
      "separator":".",
      "defaultduration":60,
      "clientDate":(new Date()).toISOString(),
      "responseType":"EDIT",
      "paragraphs": paragraphs
    };

    axios.post( 
      "https://whensday.appspot.com/whensday/api/parse", 
      JSON.stringify(requestData), 
      axiosConfig
    ).then(response => {

      this.removeAutomaticEntities();

      response.data.paragraphs.forEach((par) => {
        par.tags.forEach( (tag) => {
          if(tag.isAutomatic){
            var selectionState = SelectionState.createEmpty(par.key);
            var updatedSelection = selectionState.merge({
              focusKey: par.key,
              anchorOffset: tag.start,
              focusOffset: tag.end,
            });
            const content = this.state.editorState.getCurrentContent();
            const editorState = this.state.editorState;
            const selection = editorState.getSelection();
            const newContent = Modifier.applyEntity(
              content,
              updatedSelection,
              this.findEntityKey(tag.tag, true)
            );
            const newEditorState = EditorState.push(editorState, newContent, null);
            const newnewEditorState = EditorState.forceSelection(newEditorState, selection);
            this.setState({'editorState': newnewEditorState});
          }
        });
        })

        this.setState({'events': response.data.events});
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error', error.message);
        }
      });
  }

  removeAutomaticEntities(){
    const contentState = this.state.editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    const blocks = blockMap.map((block) => {
      let altered = false
      
      const chars = block.getCharacterList().map((char) => {
        const entityKey = char.getEntity()
  
        if (entityKey) {
          if (contentState.getEntity(char.getEntity()).data.isAutomatic) {
            altered = true
            return CharacterMetadata.applyEntity(char, null)
          }
        }
        return char
      })
  
      return altered ? block.set("characterList", chars) : block
    })
  
    const newContent =  contentState.merge({
      blockMap: blockMap.merge(blocks),
    })

    const newEditorState = EditorState.push(this.state.editorState, newContent, null);
    this.setState({'editorState': newEditorState});
  }

  findEntityKey(tag, isAutomatic){
    const content = this.state.editorState.getCurrentContent();
    for(var i = 1; i < 7; i++ ){
      if (content.getEntity(i.toString()).data.tag === tag
        && content.getEntity(i.toString()).data.isAutomatic === isAutomatic){
        return i.toString();
      }
    }
    return null;
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Whensday.at?</h1>
          <p>Whensday is a library and REST API that can extract date information from text and transform it into ical files. Use the demo below to try it yourself.</p>

          <WhensdayEditor
                onChange={this.handleChange}
                editorState ={this.state.editorState}
          />
          <TagButtons 
            onClick={this.handleTagClick}
          />
          <SendButton
            onClick={this.handleSend}
          />
          <WhensdayTable 
            data={ this.state.events } 
          />

        </header>
      </div>
    );
  }
}

export default App;